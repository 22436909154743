/*! SE Widget UI v1.86.0 (undefined) */
@font-face {
  font-display: fallback;
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/ProximaNovaRegular.314b74a3.woff2) format('woff2'),
    url(/static/media/ProximaNovaRegular.a86f1d01.woff) format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'proxima-nova';
  font-style: italic;
  font-weight: normal;
  src: url(/static/media/ProximaNovaRegularItalic.1aecfd42.woff2) format('woff2'),
    url(/static/media/ProximaNovaRegularItalic.f2d39094.woff) format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/ProximaNovaLight.8f2a5402.woff2) format('woff2'),
    url(/static/media/ProximaNovaLight.d4078c71.woff) format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'proxima-nova';
  font-style: italic;
  font-weight: 300;
  src: url(/static/media/ProximaNovaLightItalic.9ff3a65a.woff2) format('woff2'),
    url(/static/media/ProximaNovaLightItalic.55c2261d.woff) format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 600;
  src: url(/static/media/ProximaNovaSemibold.d28eff37.woff2) format('woff2'),
    url(/static/media/ProximaNovaSemibold.997440f6.woff) format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'proxima-nova';
  font-style: italic;
  font-weight: 600;
  src: url(/static/media/ProximaNovaSemiboldItalic.6ac1ad44.woff2) format('woff2'),
    url(/static/media/ProximaNovaSemiboldItalic.fa293144.woff) format('woff');
}

