@font-face {
  font-display: fallback;
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/ProximaNovaRegular.woff2') format('woff2'),
    url('../assets/fonts/ProximaNovaRegular.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'proxima-nova';
  font-style: italic;
  font-weight: normal;
  src: url('../assets/fonts/ProximaNovaRegularItalic.woff2') format('woff2'),
    url('../assets/fonts/ProximaNovaRegularItalic.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/ProximaNovaLight.woff2') format('woff2'),
    url('../assets/fonts/ProximaNovaLight.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'proxima-nova';
  font-style: italic;
  font-weight: 300;
  src: url('../assets/fonts/ProximaNovaLightItalic.woff2') format('woff2'),
    url('../assets/fonts/ProximaNovaLightItalic.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/ProximaNovaSemibold.woff2') format('woff2'),
    url('../assets/fonts/ProximaNovaSemibold.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'proxima-nova';
  font-style: italic;
  font-weight: 600;
  src: url('../assets/fonts/ProximaNovaSemiboldItalic.woff2') format('woff2'),
    url('../assets/fonts/ProximaNovaSemiboldItalic.woff') format('woff');
}
